import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'redux/store';
import {
  MinimalVideoPracticeActivity,
  SubmissionTab,
  VideoPracticeSubmission,
} from 'redux/schemas/models/video-practice';
import {
  setFeedbackActivity,
  setShowFeedbackInstruction,
  setShowPracticeFeedbackModal,
} from 'redux/actions/video-practice-feedback';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import PracticeFeedbackOverview from 'lecture_pages/components/video-practice-evaluation/feedback-overview/practice-feedback-overview';
import { CourseSupervisorContextType } from './types';
import { config } from '../../../config/pendo.config.json';
import { modalStyles } from '../components/styles';

const CourseSupervisorContext = createContext<CourseSupervisorContextType>({});

export const useCourseSupervisorContext = () => useContext(CourseSupervisorContext);

interface Props {
  children: React.ReactNode;
}

const CourseSupervisorProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const showModal: boolean = useSelector(
    state => state.app.videoPracticeFeedback?.showPracticeFeedbackModal,
  );
  const [modalState, setModalState] = useState<{
    submissionId: number;
    scenarioId: number;
  }>(null);

  const onSelectSubmission = useCallback(
    (
      submissionId: number,
      scenarioId: number,
      activity?: MinimalVideoPracticeActivity,
    ) => {
      setModalState({
        submissionId,
        scenarioId,
      });
      dispatch(setFeedbackActivity(activity?.id));
      if (activity?.progress === 'not_started') {
        dispatch(
          setShowFeedbackInstruction({
            show: true,
            practiceFeedbackCriteriaId: activity?.id,
          }),
        );
      } else {
        dispatch(
          setShowFeedbackInstruction({
            show: false,
            practiceFeedbackCriteriaId: activity?.id,
          }),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (modalState !== null) {
      dispatch(setShowPracticeFeedbackModal(true));
    }
  }, [dispatch, modalState]);

  useEffect(() => {
    if (showModal === false) {
      setModalState(null);
    }
  }, [showModal]);

  const onClosePracticeModal = useCallback(() => {
    dispatch(setShowPracticeFeedbackModal(false));
    setModalState(null);
    dispatch(setFeedbackActivity(null));
  }, [dispatch]);

  const onClickPendingSubmissionRow = useCallback(
    (submission: VideoPracticeSubmission) => {
      onSelectSubmission(
        submission.id,
        submission.scenario.id,
        submission?.activity,
      );
    },
    [onSelectSubmission],
  );

  const value = useMemo(
    () => ({
      onClickPendingSubmissionRow,
      onClosePracticeModal,
    }),
    [onClickPendingSubmissionRow, onClosePracticeModal],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(
      window?.location.hash?.replace('#!/course-supervisor-dashboard', ''),
    );
    const scenarioIdQueryParam = searchParams.get('scenarioId');
    const submissionIdQueryParam = searchParams.get('submissionId');
    if (submissionIdQueryParam && scenarioIdQueryParam) {
      setModalState({
        submissionId: Number(submissionIdQueryParam),
        scenarioId: Number(scenarioIdQueryParam),
      });
    }
  }, []);

  return (
    <CourseSupervisorContext.Provider value={value}>
      {children}
      {modalState && (
        <NvModal
          backdrop
          type={ModalType.NO_DIALOG}
          show={showModal}
          onClose={onClosePracticeModal}
          closeDataQa={config.pendo.practiceFeedback.closeOverviewModal}
          modalStyles={modalStyles}
          body={(
            <PracticeFeedbackOverview
              submissionId={modalState.submissionId}
              scenarioId={modalState.scenarioId}
              onClose={onClosePracticeModal}
              isSupervisorReview
              selectedViewProp={SubmissionTab.AUTHOR_FEEDBACK}
            />
          )}
        />
      )}
    </CourseSupervisorContext.Provider>
  );
};

export default CourseSupervisorProvider;
